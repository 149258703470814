.internal-border {
    margin: 10px;
}

.columnInv {
    border-right: 1px solid black;
    background: "#FFFFFF";
    box-sizing: border-box;
    margin: 0px;
    padding-bottom: 3px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.columnInv:last-child {
    border-right: none;
}

.lastColumn {
    border-right: 1px solid black;
    text-align: center;
}

.rowInv {
    border-bottom: 1px solid black;
}

.rowInv:last-child {
    border-bottom: none;
}
