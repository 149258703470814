.device-label {
    width: 51px;
    height: 14px;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 14px */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #4e596b;
}

.device-value {
    width: 199px;
    height: 16px;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 16px */

    display: flex;
    float: right;
    justify-content: right;
    text-align: right;
    letter-spacing: 0.02em;

    color: #888888;
}

.um {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 9px */

    display: flex;
    align-items: center;

    color: #4e596b;
}

.inversor-label {
    font-weight: 700;
    font-size: 17px;
    line-height: 100%;

    letter-spacing: 0.02em;

    color: #4e596b;
}

.valor-inversor {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* or 11px */
    text-align: right;
    letter-spacing: 0.02em;

    color: #888888;
}

.dados-do-inversor {
    margin: 0;
    font-weight: 700;
    font-size: 17px;
    line-height: 100%;
    /* identical to box height, or 12px */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #000000;
}

.stripped-line {
    width: 200px;
    height: 1px;
    border: 0.6px dashed #c4c4c4;
    transform: rotate(-90deg);
}
