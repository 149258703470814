.App {
  text-align: center;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    background-color: #00070b;
}

